import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../../../../../../../functions'
import { get_tranx_history, make_transfer, migrate_pending_balance, refresh_pending_balance } from '../../../../../../../../../functions/service_functions.js'
import { currencies } from '../../../../../../Services/AddServices/Forms/SkillSetsForm/RatesForm'
import Loader from '../../../../../../../../../assets/spinner.svg'
import { ClipboardCopyIcon } from '@heroicons/react/solid'
import { useAlert } from '../../../../../../../../../Components/UtilityComponents/ErrorContext/index.js'
import NOReviews from '../../../../../../../../../assets/Group 590.svg'


const AccountView = ({ account, uid, edit }) => {
    const [currency, setCurrency] = useState(currencies[3])
    const [transactions, setTransactions] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingPending, setLoadingPending] = useState(false)
    const [history_count, setHistoryCount] = useState(5)
    const [loadingHistory, setLoadingHistory] = useState(false)

    const alert = useAlert()


    useEffect(() => {
        setCurrency(currencies.filter(c => c.title.toLowerCase() === account?.currency?.toLowerCase())[0])
        get_tranx_history(5, uid).then((res) => {
            console.log('HISTORY ----------', res)
            setTransactions(JSON.parse(res))
        })
    }, [])

    const loadMoreHistory = (count) => {
        setLoadingHistory(true)
        get_tranx_history(count, uid).then((res) => {
            setTransactions(JSON.parse(res))
        }).then(() => setHistoryCount(count))
            .finally(() => setLoadingHistory(false))
    }

    const refresh_pending = async () => {
        setLoadingPending(true)
        refresh_pending_balance(uid).then((res) => {
            console.log(res, 'RESULS = 0')
            if (typeof res == 'number') {
                alert.setalert({
                    title: "Success",
                    body: "Refreshed! reload this page to see balance",
                    type: 'success'
                })
            } else {
                alert.setalert({
                    title: "Failed!",
                    body: res[2],
                    type: 'error'
                })
            }
        }).then(() => migrate_pending_balance())
            .finally(() => {
                setLoadingPending(false)
            })
    }

    const transferBalance = async (reference = null) => {
        setLoading(true)
        const data = {
            uid: uid,
            reason: 'Guide payout',
            recipient_code: account.payment_account.recipient_code,
            amount: account.balance,
            currency: currency.title
        }

        if (reference) {
            data['reference'] = reference
        }

        make_transfer(data).then((res) => {
            console.log(res, 'RESULS = 0')
            if (res[1] === true) {
                alert.setalert({
                    title: "Success",
                    body: "You balance will be transfered to you bank account within the day",
                    type: 'success'
                })
            } else {
                alert.setalert({
                    title: "Failed!",
                    body: res[2],
                    type: 'error'
                })
            }
        })
            .then(() => {
                // fetch('')
            })
            .catch((e) => {
                console.log('TRANSFER FAILED', e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const copyToClipboard = (e) => {
        navigator.clipboard.writeText(e).then(() => {
            alert.setalert({
                type: 'success',
                body: "copied",
                title: 'Copied'
            })
        })
    }

    const pending_notice = () => {
        const schedule = account?.payment_account?.payout_schedule?.value
        switch (schedule) {
            case "per_transaction":
                return "when the order is recieved by a courier."
            case "next_day":
                return "tomorrow."
            case "per_week":
                return "at the end of the week."
            case "per_month":
                return "at the end of the month."
            default:
                return null
        }
    }

    console.log(account)

    useEffect(() => { }, [currency, transactions])

    return (
        <div className='w-full p-0 lg:p-5 space-y-10'>
            <div className='text-2xl font-thin text-gray-500'>Get Paid</div>

            <DetailBlock title={"Account Details"} show_edit={true} editAction={edit} >
                <div>
                    <div className='w-full items-center space-x-10 grid grid-cols-5'>
                        <span className='text-lg font-medium col-span-2'>Business Name</span>
                        <span className='text-base font-light col-span-3'>{account?.payment_account?.account_name}</span>
                    </div>
                    <div className='w-full items-center space-x-10 grid grid-cols-5'>
                        <span className='text-lg font-medium col-span-2'>Bank Name</span>
                        <span className='text-base font-light col-span-3'>{account?.payment_account?.bank}</span>
                    </div>
                    <div className='w-full items-center space-x-10 grid grid-cols-5'>
                        <span className='text-lg font-medium col-span-2'>Account Number</span>
                        <span className='text-base font-light col-span-3'>{account?.payment_account?.account_number}</span>
                    </div>
                    <div className='w-full items-center space-x-10 grid grid-cols-5'>
                        <span className='text-lg font-medium col-span-2'>Payout Schedule</span>
                        <span className='text-base font-light col-span-3'>{account?.payment_account?.payout_schedule?.title}</span>
                    </div>
                </div>
            </DetailBlock>

            <DetailBlock title={"Balance"}>
                <div className='flex flex-col md:flex-row space-y-2 md:space-y-0 justify-between items-center '>
                    <div className='flex items-center space-x-2 text-gray-400'>
                        <div>Your available balance is</div>
                        <div className='text-lg font-medium'>{currency?.symbol} {numberWithCommas(parseFloat(account?.balance).toFixed(2))}</div>
                    </div>
                    <div onClick={() => account?.balance > 0 && transferBalance()} className={`px-4 cursor-pointer py-2 text-center rounded-lg w-full md:w-auto justify-center text-white ${account?.balance > 0 ? 'bg-green-500' : 'bg-gray-300'} font-semibold flex items-center space-x-2`}>
                        {loading && <img src={Loader} className='w-4 h-4 animate-spin' />}
                        <span>Withdraw Now</span>
                    </div>
                </div>
            </DetailBlock>

            <DetailBlock title={"Pending Balance"}>
                <div className='flex flex-col space-y-2 text-left '>
                    <div className='text-gray-400 italic'>This balance will become available for payout {pending_notice()}.</div>
                    <div className='flex items-center space-x-2 text-gray-400'>
                        <div>Your pending balance is</div>
                        <div className='text-sm lg:text-lg font-medium'>{currency?.symbol} {numberWithCommas(parseFloat(account?.pending_balance).toFixed(2))}</div>
                    </div>
                    <div onClick={() => refresh_pending()} className={`px-4 cursor-pointer py-2 text-center rounded-lg w-full md:w-auto justify-center text-white ${ 'bg-green-500'} font-semibold flex items-center space-x-2`}>
                        {loadingPending && <img src={Loader} className='w-4 h-4 animate-spin' />}
                        <span>Refresh Pending Balance</span>
                    </div>
                </div>
            </DetailBlock>

            <DetailBlock title="History">
                {
                    transactions?.length <= 0 ?
                        <div className=' flex flex-col items-center justify-center text-center space-y-3'>
                            <img src={NOReviews} className=" w-24 h-24" alt='guide no reviews notice image' />
                            <div className='text-gray-400'>
                                <div className='text-lg font-medium text-gray-500 '>No Transactions</div>
                                <div className='text-xs '>Your Transaction history show up here</div>
                            </div>
                        </div>
                        :

                        <div className='w-full'>
                            {/* <table className="table-auto text-center w-full hidden 2xl:block">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Paid By</th>
                                        <th>Clearance Status</th>
                                        <th>Order Type</th>
                                        <th>#Ref</th>
                                    </tr>
                                </thead>
                                <tbody className='divide-y-[1px] w-full'>
                                    {
                                        transactions?.map((trans, indx) => {
                                            return (
                                                <tr key={indx} className='text-center text-sm w-full'>
                                                    <td className='py-2'>{moment(trans.time).format('lll')}</td>
                                                    <td className='py-2 text-left'>{trans.currency?.symbol} {numberWithCommas(parseFloat(trans.amount).toFixed(2))}</td>
                                                    <td className='py-2 text-xs lowercase text-center'>{trans.payment_status}</td>
                                                    <td className='py-2 '>{trans.customer.display_name}</td>
                                                    <td className='py-2 text-xs lowercase text-center flex justify-center'>{trans.calculated_status ? <div className='w-2 h-2 bg-green-500 rounded-full' /> : <div className='w-2 h-2 bg-orange-400 rounded-full' />}</td>
                                                    <td className='py-2 text-xs lowercase text-center'>{trans.type?.split('_').join(' ')}</td>
                                                    <td className='py-2 hover:underline cursor-pointer hover:text-blue-500' onClick={() => copyToClipboard(trans.reference)}>copy</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table> */}
                            <div className='block w-full space-y-4 divide-y-2'>
                                {
                                    transactions?.map((trans, indx) => {
                                        return (<div key={indx} className='text-sm space-y-2 py-2 w-full'>
                                            <div className='flex items-start justify-between'>
                                                <div className='font-bold uppercase'>#{trans.orderId}</div>
                                                <div>{moment(trans.time).format('lll')}</div>
                                            </div>
                                            <div className='w-full flex flex-col text-textcolor justify-start items-start'>
                                                <div className=''><b className='font-medium text-base'>Customer:</b> {trans.customer.display_name}</div>
                                                <div className='lowercase'><b className='font-medium text-base'>Status:</b> {trans.payment_status || 'pending...'}</div>
                                                <td className='font-medium text-base flex items-center justify-center'>Clearance Status: {trans.calculated_status ? <div className='w-2 h-2 bg-green-500 rounded-full mx-2' /> : <div className='w-2 h-2 mx-2 bg-orange-400 rounded-full' />}</td>
                                                <div className='lowercase'><b className='font-medium text-base'>type:</b> {trans.type?.split('_').join(' ')}</div>
                                                <div className='lowercase'><b className='font-medium text-base'>Amount:</b> {trans.currency?.symbol} {numberWithCommas(parseFloat(trans.amount))}</div>
                                                <div onClick={() => copyToClipboard(trans.reference)} className='flex cursor-pointer items-center space-x-1'><b className='font-bold text-base'>Ref:</b> <span>{trans.reference}</span> <ClipboardCopyIcon className='w-4 h-4' /></div>
                                            </div>
                                        </div>)
                                    })
                                }
                            </div>
                            <div className={`w-full text-center py-2 ${loadingHistory ? 'bg-gray-400' : 'bg-primary-500'} rounded-lg font-bold text-white cursor-pointer`} onClick={() => loadMoreHistory(history_count + 5)}>{loadingHistory ? "please wait..." : "Load More"}</div>
                        </div>
                }
            </DetailBlock>

        </div>
    )
}

const DetailBlock = ({ title, show_edit = false, editAction, children }) => {
    return (
        <div className='w-auto flex flex-col rounded-md lg:rounded-xl ring-2 ring-black/10 shadow-lg'>
            <div className='flex justify-between items-center w-full px-4 lg:px-10 bg-primary-300 '>
                <div className=' py-5 w-full flex item-center text-xl rounded-t-md lg:rounded-t-xl font-bold'>{title}</div>
                {show_edit && <div onClick={() => editAction('show_form')} className='p-1 bg-white ring-2 ring-black/10 shadow-md cursor-pointer rounded-full w-7 h-7 flex justify-center items-center'>
                    <PencilAltIcon className='w-4 h-4 text-gray-400' />
                </div>}
            </div>
            <div className='w-full rounded-b-md lg:rounded-b-xl bg-white p-5'>
                {children}
            </div>
        </div>
    )
}

export default AccountView