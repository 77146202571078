import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useBilling } from '../../../../../../../../../Components/UtilityComponents/BillingContext'
import { getUiniqueID, guide_seller_profile, numberWithCommas } from '../../../../../../../../../functions'
import { updateBuisnessUserProfileProgress } from '../../../../../../../../../functions/authFunctions'
import { exchangeCurrencies } from '../../../../../../../../../functions/get_rates_api'
import { update_tranx_history } from '../../../../../../../../../functions/service_functions.js'
import { OrderTransactionStages, OrderTypes } from '../../../../../../Jobs/JobsMenu/data'
import { currencies } from '../../../../../../Services/AddServices/Forms/SkillSetsForm/RatesForm'
import Payment from '../Payment'
import { BillingPrefix, getRegistrationFee, Stages } from '../UploadID/stages'

const CollectPayment = ({ user, fee, prefix, callback, seller_uid = null }) => {

    const [reference, setRefernece] = useState('')
    const [amount, setAmount] = useState({ amount: 0, rate: 0 })

    const billing = useBilling()
    const trans_date = new Date()


    useEffect(() => {
        if (!prefix) {
            setRefernece(`${BillingPrefix.REGISTRATION}${getUiniqueID()}`)
        } else {
            setRefernece(`${prefix}${getUiniqueID()}`)
        }
    }, [])

    useEffect(() => {
        // exchangeCurrencies({ from: 'usd', to: 'ngn', amount: fee ? fee : getRegistrationFee() }).then((rate) => {
        //     console.log(rate)
        //     setAmount({ rate: parseFloat(rate.info.rate).toFixed(2), amount: parseFloat(rate.result).toFixed(2) })
        // })
        setAmount({ rate:1, amount: parseFloat(getRegistrationFee()).toFixed(2) })
    }, [reference])

    useEffect(() => {},[amount?.rate])

    const onPaymentSuccessfull = async () => {
        if (callback) {
            return await callback().then(() => {
                return {
                    type: 'success',
                    body: 'payment successfull!',
                    title: 'Success!'
                }
            }).then(() => {
                update_tranx_history({
                    data: {
                        payment_ref: reference,
                        time: trans_date.toISOString(),
                        seller: seller_uid ? {
                            uid: seller_uid
                        } : guide_seller_profile(),
                        customer: {
                            uid: user.uid
                        }
                    },
                    type: OrderTypes.ADVERTISE_APARTMENT_ORDER,
                    clearance: OrderTransactionStages.PAID
                })
            })
        }

        return await updateBuisnessUserProfileProgress({
            uid: user.uid,
            progress: Stages.UPLOAD_ID,
            payment_id: reference
        }).then(() => {
            billing.updateStage(Stages.UPLOAD_ID)
            return {
                type: 'success',
                body: 'payment successfull!',
                title: 'Success!'
            }
        })
    }

    return (
        <div className='p-1 w-full md:p-5'>
            <div className=' border-2 border-gray-300 rounded md:mx-5 px-5 flex-col lg:space-y-0 lg:flex items-start space-y-2 py-5 text-sm'>
                <div className='grid grid-cols-2 w-full font-medium items-center space-x-2'>
                    <div className='text-xs font-medium'>Registration Fee: </div>
                    <div className='text-xs'> {currencies[4].symbol} {numberWithCommas(getRegistrationFee().toFixed(2))}</div>
                </div>
                {/* <div className='grid grid-cols-2 w-full font-medium items-center space-x-2'>
                    <div className='text-xs font-medium'>Exchange Rates: </div>
                    <div className='text-xs'>{currencies[4].symbol} {amount?.rate} to one USD ($)</div>
                </div>
                <div className='grid grid-cols-2 w-full font-medium items-center space-x-2'>
                    <div className='text-xs font-medium'>Naira Equivalent: </div>
                    <div className='text-xs'>{currencies[4].symbol} {numberWithCommas(amount?.amount)}</div>
                </div> */}
            </div>
                
            <div className='w-full'>
            <Payment total={amount?.amount} currency={currencies[4]} reference={reference} callback={onPaymentSuccessfull} />
            </div>
        </div>
    )
}

export default CollectPayment