import logo from './logo.svg';
import './App.css';
import Example from './example';
import AuthProvider from './Components/UtilityComponents/AuthProvider';
import RequireNoAuth  from './Components/UtilityComponents/RequireNoAuth';
import RequireAuth from './Components/UtilityComponents/RequireAuth';
import { Route, Routes } from 'react-router';
import LoginPage from './Pages/Auth/LoginPage';
import Dashboard from './Pages/Dashboard';
import Main from './Pages/Dashboard/Screens/Main';
import Review from './Pages/Dashboard/Screens/Review';
import Jobs from './Pages/Dashboard/Screens/Jobs';
import Ads from './Pages/Dashboard/Screens/Ads';
import Services from './Pages/Dashboard/Screens/Services';
import Home from './Pages/Home';
import Assistant from './Components/Assistant';
import SignUp from './Pages/Auth/SignUp';
import ApartmentsView from './Pages/ApartmentsView';
import HomePage from './Pages/Home/HomePage';
import SelectAccountType from './Pages/Dashboard/Screens/SelectAccountTypePage';
import Timetable from './Pages/Dashboard/Screens/Timetable';
import AlertProvider from './Components/UtilityComponents/ErrorContext';
import PasswordReset from './Pages/Auth/PasswordReset';
import NewPassword from './Pages/Auth/NewPassword';
import ServiceProviderProfile from './Pages/Services/ServiceProviderProfile';
import DraftServiceProviderProfile from './Pages/Services/DraftServiceProviderProfile';
import AccountType from './Components/UtilityComponents/AccountType';
import AccountGroups from './Pages/Dashboard/Screens/AccountGroups';
import DashboardWrapper from './Pages/Dashboard/DashboardComponents/DashboardWrapper';
import VerifyEmail from './Pages/Auth/VerifyEmail';
import { GlobalDebug } from './functions/remove-console.logs';
import { MODE } from './assets/url';
import ServicesPage from './Pages/Services';
import ServiceProvidersList from './Pages/Services/ServiceProvidersList';
import FinishPayments from './Pages/Payments/FinishPayments';
import AllServices from './Pages/Services/AllServices';
import NotFoundPage from './Pages/Utility/NotFoundPage';
import PrivacyPolicy from './Pages/Utility/PrivacyPolicy';
import ChatContext from './Components/UtilityComponents/ChatsContext';
import PlaceOrderContext from './Components/UtilityComponents/PlaceOrderContext';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { createRef, useEffect, useState } from 'react';
import JobsContext from './Components/UtilityComponents/JobsContext';
import Housing from './Pages/Dashboard/Screens/Housing';
import DashboardApartmentsList from './Pages/Dashboard/Screens/Housing/DashboardApartmentsList';
import HousingContext from './Components/UtilityComponents/HousingContext';
import HousingForm from './Components/UtilityComponents/HousingFormContext'
import ApartmentInfo from './Pages/ApartmentsView/ApartmentInfo';
import BlogView from './Pages/Blog/BlogView';
import BlogList from './Pages/Blog/BlogList';
import BlogContext from './Components/UtilityComponents/BlogContext';
import HousingSearchContext from './Components/UtilityComponents/HousingSearchContext';
import ApartmentsSearchPage from './Pages/ApartmentsSearchPage';
import LogView from './Pages/Dashboard/Screens/Utils/LogView';
import SPaymentContext from './Components/SPaymentProcessor';
import Business from './Pages/Dashboard/Screens/Business';
import BusinessFormContext from './Components/UtilityComponents/BusinessFormContext';
import AppRedirect from './AppRedirect';
import PluginView from './Pages/Dashboard/Screens/PluginView';
import Modal from './Components/Modal';
import { ExclamationIcon } from '@heroicons/react/outline';




if (MODE !== 'DEV') {
    GlobalDebug(false, true)
}

const scrollRef = createRef()


function App() {

    const [distanceFromTopOfScreen, setDistanceFromTopOfScreen] = useState(0);
    const [browserZoomLevel, setbrowserZoomLevel] = useState(100);
    const [openZoomNotifier, setOpenZoomNotifier] = useState(false);

    useEffect(() => {

    }, [])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = (event) => {
        let scrollTop = scrollRef.current.scrollTop
        setDistanceFromTopOfScreen(scrollTop)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
            if(browserZoomLevel > 110){
                setOpenZoomNotifier(true)
            }else{
                setOpenZoomNotifier(false)
            }
            setbrowserZoomLevel(browserZoomLevel)
        })

        return () => window.removeEventListener('resize', () => null)
    }, [])


    return (
        <div ref={scrollRef} onScroll={handleScroll} className="App h-full w-full overflow-y-auto scrollbar">
            {/* RequireNoAuth Wrapper for routes where user has to be logged out to see, 
      and RequireAuth Wrapper for pages user can see only if logged in,
      and wrapp all routes in AuthProvider to access the auth state using context */}
            <Assistant>
                <HousingSearchContext>
                    <AlertProvider>
                        <ChatContext>
                            <AuthProvider>
                                <AccountType>
                                    <PlaceOrderContext>
                                        <JobsContext>
                                            <Routes>
                                                <Route path="/" element={
                                                    <Home />
                                                } >
                                                    <Route path="/" element={<RequireNoAuth><HomePage /></RequireNoAuth>} />
                                                    <Route path="/apartments" element={<ApartmentsView />} />
                                                    <Route path="/apartments/search" element={<ApartmentsSearchPage />} />
                                                    <Route path="/apartments/:id" element={<ApartmentInfo distanceFromTopOfScreen={distanceFromTopOfScreen} />} />
                                                    <Route path="/services" element={<ServicesPage />} />
                                                    <Route path="/services/list/:category" element={<ServiceProvidersList />} />
                                                    <Route path="/services/list/all" element={<AllServices />} />
                                                    <Route path="/finish_payment" element={<FinishPayments />} />
                                                    <Route path="/services/:id" element={<ServiceProviderProfile />} />
                                                    <Route path="/blog/:id" element={<BlogView distanceFromTopOfScreen={distanceFromTopOfScreen} />} />
                                                    <Route path="/blog" element={<BlogList />} />
                                                </Route>
                                                <Route path="/app/:resource/:id" element={
                                                    <AppRedirect />
                                                } />
                                                <Route path="/verify_email" element={
                                                    <RequireAuth>
                                                        <VerifyEmail />
                                                    </RequireAuth>
                                                } />

                                                <Route path='/dashboard' element={
                                                    <RequireAuth>
                                                        <BusinessFormContext>
                                                            <Dashboard />
                                                        </BusinessFormContext>
                                                    </RequireAuth>
                                                } >
                                                    <Route path="/dashboard/main" element={<Main />} />
                                                    <Route path="/dashboard/business" element={
                                                        <BusinessFormContext>
                                                            <Business />
                                                        </BusinessFormContext>
                                                    } />
                                                    <Route path="/dashboard/timetable" element={
                                                        <BlogContext>
                                                            <Timetable />
                                                        </BlogContext>} />
                                                    <Route path="/dashboard/services" element={<Services />} />
                                                    <Route path="/dashboard/housing" element={
                                                        <HousingContext>
                                                            <HousingForm>
                                                                <Housing />
                                                            </HousingForm>
                                                        </HousingContext>
                                                    } />
                                                    <Route path="/dashboard/housing/list" element={
                                                        <HousingContext>
                                                            <HousingForm>
                                                                <DashboardApartmentsList />
                                                            </HousingForm>
                                                        </HousingContext>
                                                    } />
                                                    <Route path="/dashboard/:account/:business_id/plugin/:name" element={
                                                        <PluginView />
                                                    } />
                                                    <Route path="/dashboard/services/category/:category" element={<ServiceProvidersList />} />
                                                    <Route path="/dashboard/account_setup" element={<DashboardWrapper title="Account Setup"><AccountGroups /></DashboardWrapper>} />
                                                    <Route path="/dashboard/reviews/:id" element={<Review />} />
                                                    <Route path="/dashboard/jobs" element={<Jobs />} />
                                                    {/* <Route path="/dashboard/ads" element={<Ads />} /> */}

                                                </Route>
                                                <Route path="/login" element={
                                                    <RequireNoAuth>
                                                        <LoginPage />
                                                    </RequireNoAuth>
                                                } />
                                                <Route path="/signup" element={
                                                    <RequireNoAuth>
                                                        <SignUp />
                                                    </RequireNoAuth>
                                                } />
                                                <Route path="/reset_password" element={
                                                    <RequireNoAuth>
                                                        <PasswordReset />
                                                    </RequireNoAuth>
                                                } />
                                                <Route path="/new_password" element={
                                                    <RequireNoAuth>
                                                        <NewPassword />
                                                    </RequireNoAuth>
                                                } />

                                                <Route path="/draft_services/:uid" element={<DraftServiceProviderProfile />} />
                                                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                                                <Route path="/log" element={<LogView />} />
                                                <Route path="*" element={<NotFoundPage />} />
                                            </Routes>
                                        </JobsContext>
                                    </PlaceOrderContext>
                                </AccountType>
                            </AuthProvider>
                        </ChatContext>
                    </AlertProvider>
                </HousingSearchContext>
            </Assistant>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="guide_cookie"
                style={{ background: "#1E1627" }}
                buttonStyle={{ color: "#8D29FF", fontSize: "18px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>
            <Modal hideOnMobile open={openZoomNotifier} setOpen={setOpenZoomNotifier} showBackground="">
                <div className='relative bg-white max-w-xl mx-auto rounded-xl shadow-2xl ring-1 ring-black/5 divide-y divide-gray-100'>
                    <div className='flex flex-col items-start space-y-2 h-32 bg-red-100 rounded-t-xl px-4 py-2 text-red-700'>
                        <div className='flex items-center space-x-2 py-2 '>
                            <ExclamationIcon className='w-10 h-10' />
                            <div className='font-semibold text-lg'>Adjust browser zoom</div>
                        </div>
                        <div className='text-sm font-medium '>Your browser is zoomed past the recomended range for viewing this application.</div>
                    </div>
                    <div className='w-full bg-white p-4 rounded-b-xl'>
                        <div className='text-sm font-medium italic '>Adjust your zoom level to a range between 90% and 100% for best UI experience.</div>
                        {/* <div className='py-5 flex flex-col space-y-2'>
                            <div>confirm you want to delete this {item_type} by typing "Yes" in the prompt below:</div>
                            <input onChange={(e) => setYes(e.target.value)} placeholder='Yes' text={yes} className='w-[60%] rounded-md p-2 outline-2 border-2 border-black/10 ' />
                        </div> */}
                        <div className='w-full pt-10 flex items-center space-x-4 justify-end'>
                            <div onClick={() => setOpenZoomNotifier(false)} className={`py-2 px-4  rounded-md bg-gray-300 text-gray-600 hover:font-bold cursor-pointer`}>Ok</div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    );
}

export default App;
