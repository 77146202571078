import React, { useState } from 'react'
import CheckerBox from '../../../../../../../DashboardComponents/CheckerBox'
import Loader from '../../../../../../../../../assets/spinner.svg'
import { useAuth } from '../../../../../../../../../Components/UtilityComponents/AuthProvider'
import { Stages } from '../UploadID/stages'
import { updateBuisnessUserProfileProgress } from '../../../../../../../../../functions/authFunctions'
import { useBilling } from '../../../../../../../../../Components/UtilityComponents/BillingContext'
import doc from './text.json'
import moment from 'moment'
import { useSuzi } from '../../../../../../../../../Components/Assistant'


const BillingOverView = () => {
    const [agreed, setAgreed] = useState(false)
    const [loading, setLoading] = useState(false)

    const auth = useAuth()
    const suzi = useSuzi()
    const billing = useBilling()
    const user = auth.user.uid ? auth.user : auth.user.user

    const updateProfile = () => {

    // if (process.env.REACT_APP_MODE === 'DEV'){
    //     return suzi.addMessage({
    //         text: (
    //             <div className='flex flex-col'>
    //                 <span>Hello,</span>
    //                 <span>Sorry you are not able to verify your account at this time. This feature is almost ready and will be available very coon.</span>
    //             </div>
    //         ),
    //         timeout:5000
    //     })
    // }

        setLoading(true)
        updateBuisnessUserProfileProgress({
            uid: user.uid,
            progress: Stages.REGISTRATION_FEE,
        }).then((res) => {
            billing.updateStage(Stages.REGISTRATION_FEE)
            setLoading(false)
        })
    }

    return (
        <div className='w-full h-auto py-5 overflow-y-auto scrollbar'>
            <div className='h-auto text-center '>
                <div className='space-y-5 px-5'>
                    <div className='space-y-2'>
                        <div className='font-bold text-4xl mb-5 text-primary-500'>Overview</div>
                        <div className='font-medium my-5 text-left text-primary-500'>Last Updated: {moment(doc.lastpdate).format('MMMM Do YYYY, h:mm A')} </div>
                        <div className=' text-justify'>{doc.intoduction}</div>
                    </div>
                    <div className='py-4 space-y-4'>
                        {
                            doc.overview.map((section, indx) => {
                                return (
                                    <div key={indx}>
                                        <div className='font-semibold my-5 text-lg text-left text-primary-500 '>{section.title}:</div>

                                        <div className='text-justify pb-4'>
                                            {section.body}
                                        </div>
                                        {
                                            section.multiple_bodies ?
                                                <div className='text-justify space-y-4'>
                                                    {
                                                        section.multiple_bodies.map((mb, mbindx) => {
                                                            return (
                                                                <div key={mbindx}>
                                                                    <span className='text-md font-medium text-primary-500'>{mb.title}: </span>
                                                                    {mb.body}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='pt-20 space-y-5 px-5'>
                    <div className='flex space-x-4 items-center text-left font-medium'>
                        <CheckerBox setValue={setAgreed} value={agreed} />
                        <span> I have read and understood the information above. </span>
                    </div>
                    <div onClick={() => !(loading && agreed) && updateProfile()} className={`w-full py-2 flex justify-center text-white ${(loading || !agreed) ? 'bg-gray-300 cursor-not-allowed' : 'bg-primary-500 hover:bg-primary-800'} rounded-md text-center font-bold cursor-pointer `}>
                        {loading ? <img src={Loader} className='w-4 h-4 animate-spin' /> : "Confirm"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BillingOverView