import { get_fetch, post_fetch } from "../../Pages/Dashboard/Screens/Timetable/functions";
import { PLUGIN_SERVER_URL } from "../../assets/url";


class Plugins {
    constructor() {
        const plugins = []
    }

    async getMarketPlaceData() {
        return await get_fetch(`marketplace`,null, PLUGIN_SERVER_URL).then(async res => {
            this.plugins = res
            return this
        })
    }

    async installPlugin(data) {
        const { plugin: plug, account, user, business } = data
        const name = plug.name

        plug['active'] = false
        plug['install_date'] = new Date().toISOString()

        const plugin = this.plugins.filter(plugin => plugin.name === name)

        if (plugin.length <= 0) {
            throw new Error(`couldn't find plugin ${name}`)
        }

        const copy = JSON.parse(JSON.stringify(plug))

        copy['pretty-name'] = copy.marketplace['pretty-name']
        copy['supported_account_types'] = copy.marketplace['supported_account_types']
        copy['account_id'] = account.id

        delete copy.marketplace
        console.log(copy, user, account, business)
        return await post_fetch('api/business/install_plugin', { "id": account.id, "plugin": copy })
    }

    async uninstallPlugin(data) {
        const { name, id } = data
        return await post_fetch('api/business/uninstall_plugin', { "id": id, "plugin": { name, id } })
    }

    async togglePlugin(data) {
        return await post_fetch('api/business/toggle_plugin', { "id": data.account_id, "plugin": data })
    }
}

export default Plugins