import { CheckIcon, CreditCardIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { ReactComponent as Loader } from "../../../../../../../../../assets/spinner.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../../../../../../Components/UtilityComponents/AuthProvider";
import { numberWithCommas } from "../../../../../../../../../functions";
import { makePayment } from "../../../../../../../../../functions/authFunctions";
import DashboardFormField from "../../../../../../../DashboardComponents/DashboardFormField";
import { currencies } from "../../../../../../Services/AddServices/Forms/SkillSetsForm/RatesForm";
import { useAlert } from "../../../../../../../../../Components/UtilityComponents/ErrorContext";
import PaystackPop from "@paystack/inline-js";

// const user = new User()

const Payment = ({
  total = 923,
  currency = currencies[3],
  reference,
  callback,
}) => {
  const [card_num, setCardNum] = useState("");
  const [card_date, setCardDate] = useState("");
  const [card_cvv, setCardCvv] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editPersonalDetails, setEditPersonalDetails] = useState(false);
  const [editAddress, setEditAddress] = useState(false);

  const auth = useAuth();
  const alert = useAlert();
  const userIsAvailable = auth.user !== null;
  const uid = auth.user.uid;
  const userData = auth.user.user;

  const paystack = new PaystackPop();

  useEffect(() => {
    if (!userData && uid) {
      auth.getuser(uid).then((res) => {
        console.log(res);
        const { user } = res;
        setName(user.display_name);
        setEmail(user.email);
        setPhone(user.phone_number);
      });
    } else {
      setName(userData.display_name);
      setEmail(userData.email);
      setPhone(userData.phone_number);
    }
  }, []);

  useEffect(() => {
    if (email !== "" && phone !== "" && name !== "") {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [phone, name]);

  const handleCheckout = () => {
    const data = {
      email: email,
      amount: total * 100,
      currency: currency.title,
      reference,
    };
    if (!(ready && !loading)) return;
    setLoading(true);
    setTimeout(async () => {
      // const url = await makePayment(data)

      paystack.newTransaction({
        // other params
        key:
          process.env.REACT_APP_MODE === "DEV"
            ? process.env.REACT_APP_PAYSTACK_API_TEST_PUBLIC_KEY
            : process.env.REACT_APP_PAYSTACK_API_PROD_PUBLIC_KEY,
        ...data,
        onSuccess: (transaction) => {
          // Payment complete! Reference: transaction.reference
          console.log(`Payment complete! Reference: ${transaction.reference} `);
          callback(transaction).then((response) => {
            alert.setalert(response);
          });
        },
        onCancel: (e) => {
          // user closed popup
          console.log(`POP closed: ${e} `);
          alert.setalert({
            type: "error",
            body: "transaction cancelled",
            title: "Error!",
          });
          setLoading(false);
          return;
        },
      });

      // console.log(url, 'URL')

      // let code = url[3].authorization_url.split('/')
      // code = code[code.length - 1]

      setLoading(false);
    }, 2000);
  };

  const updateAddress = () => {
    setEditAddress(!editAddress);
  };

  const updatePerson = () => {
    const ph = new RegExp('^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$')
    if(!ph.test(phone)) {
      alert.setalert({
        type: "error",
        body: "Not valid Mobile Number",
        title: "Error!",
      });
      return
    }
    setEditPersonalDetails(!editPersonalDetails);
  };

  return (
    <div className="flex-1 md:w-full flex flex-col space-y-4 md:p-5 py-5">
      <div
        className={`border-2 ${
          !ready ? "border-red-400 animate-pulse" : "border-gray-300"
        } rounded w-full h-fit`}
      >
        <div className="px-5 py-3  flex flex-col  md:flex-row items-center justify-start">
          <div className="flex items-start">
            <div className="mr-4 w-5 h-5 flex items-center justify-center border-2 border-gray-200 rounded-full">
              a
            </div>
            <div className="flex flex-col text-sm font-medium">
              <div className="flex items-center space-x-4">
                <span className="font-bold">USER</span>
                {userIsAvailable && (
                  <span>
                    <CheckIcon className="w-3 h-3" />
                  </span>
                )}
              </div>
              {userIsAvailable ? (
                <div className="flex flex-col items-start space-y-1 text-xs ">
                  <span> {name} </span>
                  <span> {email} </span>
                  <span>{phone}</span>
                </div>
              ) : (
                <div className="flex items-center space-x-4 text-xs ">
                  <span className="text-gray-400">No User Available</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex w-full md:w-auto text-center mt-2 md:mt-0 justify-center md:ml-auto items-center py-2 px-3 rounded bg-gray-100 text-sm font-medium">
            {!editPersonalDetails && auth.user && (
              <span
                onClick={() => setEditPersonalDetails(!editPersonalDetails)}
              >
                EDIT
              </span>
            )}
            {editPersonalDetails && auth.user && (
              <span onClick={() => updatePerson()}>DONE</span>
            )}
            {!auth.user && <Link to="/signin">Login To Continue</Link>}
          </div>
        </div>
        {editPersonalDetails && (
          <div>
            <DashboardFormField
              name="Full Name"
              placeholder="John Micheal Doe"
              setText={setName}
              text={name}
            />
            <DashboardFormField
              name="Phone Number"
              placeholder="+00 000 0000 000"
              mode="tel"
              setText={setPhone}
              text={phone}
            />
          </div>
        )}
      </div>
      <div className="px-5 py-3 border-2 flex items-center rounded justify-start bg-gray-200">
        <div className="mr-4 w-5 h-5 flex items-center justify-center border-2 text-white bg-black border-gray-200 rounded-full">
          b
        </div>
        <div className="flex flex-col text-sm font-medium">
          <div className="flex items-center space-x-4">
            <span className="font-bold">PAYMENT METHOD</span>
            {!auth.user && (
              <span className="font-light">(Login To Continue)</span>
            )}
          </div>
        </div>
      </div>
      {auth.user && (
        <div className="w-full">
          <div className="w-full px-5 py-3 flex items-center rounded justify-start">
            <div className="mr-4 w-5 h-5 flex items-center justify-center border-2 text-white bg-black border-gray-200 rounded-full"></div>
            <div className="flex flex-col text-sm font-medium">
              <div className="flex items-center space-x-4">
                <span className="w-16 h-16 flex items-center justify-center border-2 rounded-xl">
                  <CreditCardIcon className="w-10 h-10" />
                </span>
                <span className="font-medium">Credit / Debit Card Payment</span>
              </div>
            </div>
          </div>

          <div
            onClick={() => handleCheckout()}
            className={`w-auto ${
              ready && !loading
                ? "bg-black cursor-pointer hover:bg-gray-900"
                : "bg-gray-300 cursor-not-allowed"
            } space-x-2 font-bold text-md p-4 text-white rounded-xl flex items-center justify-center`}
          >
            {loading && <Loader className="w-5 h-5 animate-spin" />}
            <span>Pay</span>
            <span>
              {currency?.symbol ? currency.symbol : "₦"}
              {numberWithCommas(total)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Payment;
