/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useAuth } from "../../Components/UtilityComponents/AuthProvider";
import { load_user } from "../../functions/authFunctions";
import { store_user_data } from "../../store/actions/authActions";
import Sidebar, { BottomBar } from "./DashboardComponents/SideBar";
import { useBusinessForm } from "../../Components/UtilityComponents/BusinessFormContext";
import { AccountTypes } from "./Screens/SelectAccountTypePage/accounts";

const Dashboard = ({ current_user, business, storeUser }) => {
  const auth = useAuth();
  const uid = current_user?.user?.uid;
  const [user, setUser] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const _business = useBusinessForm();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      userData()
        .then((user_1) => {
          setUser(user_1);
          storeUser({ user: user_1 });
        })
        .then(() => {
          const _from = location.state?.from;

          if (_from) {
            if (_from.state?.from) {
              navigate(_from.state.from.pathname, { replace: true });
            } else {
              navigate(location.state.pathname, { replace: true });
            }
          }
        });
    }

    return () => {
      mounted = false;
      setUser(null);
    };
  }, []);

  useEffect(() => {
    if (auth.user?.role === "unassigned") {
      navigate("/dashboard/account_setup", { state: { from: location } });
    }
  }, [auth.user]);

  useEffect(() => {
    setUser(current_user.user);
    let accounts = current_user.user?.groupIDs;
    accounts =
      Array.isArray(accounts) && accounts.length > 0 ? accounts[0]?.type : null;

    if (location?.pathname == "/dashboard" && accounts) {
      if (accounts === AccountTypes.SERVICE_PROVIDER) {
        // navigate("/dashboard/services");  original
        navigate("/dashboard/business");
      } else if (accounts === AccountTypes.STUDENT) {
        // navigate("/dashboard/student"); original
        navigate("/dashboard/business");
      } else if (accounts === AccountTypes.BUSINESS_OWNER) {
        navigate("/dashboard/business");
      } else if (accounts === AccountTypes.HOUSING_AGENT) {
        navigate("/dashboard/housing");
      } else {
        if (accounts && current_user.user?.role !== "unassigned") {
          navigate("/dashboard/account_setup");
        }
      }
    }

    if (location?.pathname == "/dashboard" && !location.state) {
      navigate("/dashboard/account_setup", { state: { from: location } });
    }

    if (!accounts) {
      navigate("/dashboard/account_setup", { state: { from: location } });
    }
  }, [current_user, location.pathname]);

  const userData = async () => {
    const { user } = await auth.getuser(uid);
    return user;
  };
  return (
    <div className="h-screen">
      <div className="h-full w-full bg-primary-200 flex overflow-hidden relative">
        <Sidebar user={user} business={business} />
        <BottomBar user={user} current_user={current_user} />
        <div className="dashboard">
          <Outlet context={{ user }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    current_user: state.user?.data,
    business: state.business?.payload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    storeUser: (data) => dispatch(store_user_data(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
