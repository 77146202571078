import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../../../../../Components/UtilityComponents/AuthProvider";
import { get_user_identification } from "../../../../../../../../../functions/authFunctions";
import Loader from "../../../../../../../../../assets/spinner.svg";

const ActivateAccount = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await get_user_identification(auth.user.uid)
        .then((u) => {
          console.log(u);
          setUser({
            ...u?.other,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, []);

  useEffect(() => {
    console.log(user);
  }, [user, loading]);

  return loading ? (
    <div className="w-full h-full flex justify-center items-center">
      <img src={Loader} className="w-10 h-10 animate-spin" />
    </div>
  ) : (
    <div className="w-auto shadow-md h-auto p-5 bg-white rounded-xl mx-5 flex flex-col space-y-4 items-center">
      {user?.callScheduledTime ? (
        <div className="flex flex-col space-y-4 items-center">
          <div className=" w-52 h-52">
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/video-call-4120333-3420300.png"
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            Video Call{" "}
            <span className="text-lg font-bold">
              {" "}
              {moment(user?.callScheduledTime).fromNow()}
            </span>
          </div>
          <div className="py-4 text-left w-full px-5">
            <div className="text-lg font-bold">How To Prepare</div>
            <div className="flex flex-col space-y-3 py-2">
              <span>
                Prepare your documents. You'll be required to show the documents
                you submitted.
              </span>
              <span>Find a place with connection to the internet.</span>
              <span>
                Login to your Zoom account, create an account{" "}
                <a className=" underline text-blue-600" href="zoom.us">
                  here
                </a>{" "}
                if you don't already have one.
              </span>
            </div>
          </div>
          <a
            className="p-4 w-full bg-primary-500 text-white text-lg font-semibold text-center rounded-lg"
            href={user?.eventCalendarLink}
            target="_blank"
          >
            View Calendar
          </a>
        </div>
      ) : (
        <div className="flex flex-col space-y-4 items-center text-center">
          <div className="text-2xl font-bold">Account Activated!</div>
          <div className="py-4 w-full px-5">
            <div className="text-lg font-medium">
              Congratulations, your account is active.
            </div>
            {/* <div className='flex flex-col space-y-3 py-2'>
                                <span>Prepare your documents. You'll be required to show the documents you submitted.</span>
                                <span>Find a place with connection to the internet.</span>
                                <span>Login to your Zoom account, create an account <a className=' underline text-blue-600' href="zoom.us">here</a> if you don't already have one.</span>
                            </div> */}
          </div>
          {/* <a className='p-4 w-full bg-primary-500 text-white text-lg font-semibold text-center rounded-lg' href={user?.eventCalendarLink} target="_blank" >View Calendar</a> */}
        </div>
      )}
    </div>
  );
};

export default ActivateAccount;
