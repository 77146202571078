import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { createContext } from 'react'
import { connect } from 'react-redux'
import { doFilter } from '../../../Pages/ApartmentsView/functions'

const init = {
    priceRange: [],
    bedrooms: { count: [0], exact: false },
    bathrooms: { count: [0], exact: false },
    size: 0,
    address: null,
    results: null,
    loading: false,
    currency: null,
    propertyType: [],
    lettingPeriod: { count: [0], exact: false },
    billsIncluded: [],
    limit: 0,
    setLimit: () => null,
    setPropertyType: () => null,
    setLettingPeiod: () => null,
    setBillsIncluded: () => null,
    setCurrency: () => null,
    setPriceRange: () => null,
    setBedrooms: () => null,
    setBathrooms: () => null,
    setAddress: () => null,
    Search: () => null
}

const HousingSearchContextProvider = createContext(init)

const HousingSearchContext = ({ children, userx }) => {
    const user = userx?.user
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(0)
    const [currency, setCurrency] = useState([])
    const [propertyType, setPropertyType] = useState([])
    const [billsIncluded, setBillsIncluded] = useState([])
    const [lettingPeriod, setLettingPeiod] = useState({ count: [0], exact: false })
    const [priceRange, setPriceRange] = useState([])
    const [bedrooms, setBedrooms] = useState({ count: [0], exact: false })
    const [bathrooms, setBathrooms] = useState({ count: [0], exact: false })
    const [address, setAddress] = useState(null)
    const [results, setResults] = useState(null)
    const [size, setSize] = useState(0)



    useEffect(() => {

    }, [])


    const Search = () => {
        const data = {
            option: "Apartments",
            min_rent: priceRange[0],
            max_rent: priceRange[1],
            bedrooms: bedrooms.count <= 0 ? { count: [0], exact: false } : bedrooms,
            bathrooms: bathrooms.count <= 0 ? { count: [0], exact: false } : bathrooms,
            bills_included: billsIncluded,
            letting: lettingPeriod.count <= 0 ? { count: [0], exact: false } : lettingPeriod,
            propertyType,
            furnished: [],
            facilities: [],
            minsToBus: [],
            sortBy: [],
            area: [],
            rent: [],
            currency: [currency],
            distance: 100,
            limit: limit > 0 ? limit : null,
            position: {
                longitude: address?.latlon?.lng,
                latitude: address?.latlon?.lat
            }
        }

        if (!(data.position.longitude && data.position.latitude) || !data.bedrooms?.count[0] > 0) return
        let url = `https://housing.guideutility.co/tr/map?lat=${data.position.latitude}&lng=${data.position.longitude}&_radius=${data.distance}&priceMax=${data.max_rent}&priceMin=${data.min_rent}&bedrooms=${data.bedrooms.count}`
        url += `&bedrooms=${data.letting.exact}&currency=${data.currency[0].name}&currency=${data.currency[0].title}&lettingPeriod=${data.letting.count}&lettingPeriod=${lettingPeriod.exact}&type=1`
        if(data.bills_included.length > 0){
            data.bills_included.map((i) => {
                url += `&billsIncluded=${i}`
            })
        }

        if(data.propertyType.length > 0){
            data.propertyType.map((i) => {
                url += `&propertyType=${i}`
            })
        }

        window.open(url, "_blank");
        return

        setLoading(true)
        console.log(data.limit)
        doFilter(data).then((res) => {
            setResults(res.v)
            setSize(res.len)
        }).catch(e => console.warn(e, 'Error Searching')).finally(() => {
            setLoading(false)
        })
    }

    const values = {
        priceRange,
        bedrooms,
        address,
        results,
        loading,
        size,
        currency,
        lettingPeriod,
        billsIncluded,
        propertyType,
        limit,
        setLimit,
        setPropertyType,
        setCurrency,
        setPriceRange,
        setBedrooms,
        setAddress,
        setBillsIncluded,
        setLettingPeiod,
        Search
    }

    return (
        <HousingSearchContextProvider.Provider value={values}>
            {children}
        </HousingSearchContextProvider.Provider>
    )
}

export const useHousingSearch = () => useContext(HousingSearchContextProvider)

const mapStateToProps = (state) => {
    return {
        userx: state.user?.data
    }
}


export default connect(mapStateToProps)(HousingSearchContext)