import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from "react-router";
import { useAuth } from '../AuthProvider';
import { connect } from 'react-redux';


function RequireNoAuth({ children, current_user }) {
  let auth = useAuth();
  let location = useLocation();

  useEffect(() => {
    if(auth.PageRefreshcounter > 99){
      auth.signout()
      return
    }
    auth.setPageRefreshCounter(c => c + 1)
  }, [])

  if (auth.user && current_user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
}

const mapStateToProps = (state) => {
  return {
    current_user: state.user?.data,
    business: state.business?.payload,
  };
};


export default connect(mapStateToProps, null)(RequireNoAuth)
