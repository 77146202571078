export const Stages = {
    INTRODUCTION: "introduction",
    REGISTRATION_FEE: "registeration_fee",
    UPLOAD_ID: "upload_id",
    SCHEDULE_CALL:"schedule_call",
    ACTIVATED_ACCOUNT:'activated_account'
}

export const getRegistrationFee = () => 3500

export const BillingPrefix = {
    SERVICES:'GDSV-',
    REGISTRATION:'GDRG-',
    HOUSING_ADVERTISMENT:'GDHA-'
}